/* http://meyerweb.com/eric/tools/css/reset/
   v2.0 | 20110126
   License: none (public domain)
*/

html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
	margin: 0;
	padding: 0;
	border: 0;
	font-size: 100%;
	font: inherit;
	vertical-align: baseline;
}
/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure,
footer, header, hgroup, menu, nav, section {
	display: block;
}
body {
	line-height: 1;
}
ol, ul {
	list-style: none;
}
blockquote, q {
	quotes: none;
}
blockquote:before, blockquote:after,
q:before, q:after {
	content: '';
	content: none;
}
table {
	border-collapse: collapse;
	border-spacing: 0;
}


@font-face {
  font-family: 'Inter';
  src: url("fonts/Inter-Regular.woff2") format("woff2"); }

@font-face {
  font-family: 'Inter';
  src: url("fonts/Inter-Medium.woff2") format("woff2");
  font-weight: 500; }

@font-face {
  font-family: 'Inter';
  src: url("fonts/Inter-SemiBold.woff2") format("woff2");
  font-weight: 600; }

@font-face {
  font-family: 'Inter';
  src: url("fonts/Inter-Bold.woff2") format("woff2");
  font-weight: 700; }

* {
  box-sizing: border-box; }

html {
  height: 100%; }

body, #root {
  height: 100%;
  scroll-behavior: smooth; }

body {
  margin: 0;
  font-family: 'Inter', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  font-size: 16px;
  line-height: 1.3;
  text-rendering: geometricPrecision; }

h2 {
  font-size: 24px;
  font-weight: 500; }

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace; }

a {
  color: inherit; }

a:visited {
  color: inherit; }

b {
  font-weight: 700; }

input {
  font-family: 'Inter';
  font-size: 16px; }

button {
  font-family: 'Inter';
  font-size: 13px;
  cursor: pointer; }

small {
  font-size: 13px; }

.white {
  color: white; }

.bold {
  font-weight: 700; }

.semi-bold {
  font-weight: 600; }

.medium {
  font-weight: 500; }

.embed-youtube {
  position: relative;
  padding-bottom: 56.25%; /* - 16:9 aspect ratio (most common) */
  /* padding-bottom: 62.5%; - 16:10 aspect ratio */
  /* padding-bottom: 75%; - 4:3 aspect ratio */
  padding-top: 30px;
  height: 0;
  overflow: hidden;
}

.embed-youtube iframe,
.embed-youtube object,
.embed-youtube embed {
  border: 0;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}